import { AbiItem } from 'web3-utils';
import abiDecoder from 'abi-decoder';
import { TokenAbi } from './abi/venom/DexAbi';

const ERC20 = require('./abi/evm/ERC20V4.json');
const MultiVault = require('./abi/evm/MultiVault.json');
const EverGetV3 = require('./abi/evm/EverGetV3.json');
const DexTaxV3 = require('./abi/evm/DexTaxV3.json');
const EverGetV2 = require('./abi/evm/EverGetV2.json');

abiDecoder.keepNonDecodedLogs();

export const ABI = {
  TokenAbi,
  ERC20,
  MultiVault,
  EverGetV3,
  DexTaxV3,
  EverGetV2,
};
