import { AxiosResponse } from 'axios';
import httpClient from './httpClient';

export type BenificiaryInfoResponse = {
  address: string;
  fee: number;
};

type DataValidateRequest = {
  chainId: number;
  clientSwap: {
    data: string;
    sellTokenAddress: string;
    buyTokenAddress: string;
    to: string;
  };
  spender: string;
  totalAmount: string;
};

type DataValidateResponse = {
  signature: string;
  valid: boolean;
};

export type TokenItem = {
  name: string;
  symbol: string;
  logoURI: string;
  isCore: boolean;
  contractAddress: string;
  simplexSymbol: string;
  networks: number[];
  decimals: number;
};

const yoshiApi = {
  getIconByTokenAddress(chain: string, tokenAddress: string) {
    return httpClient.get(`/icons/${chain}/${tokenAddress}`);
  },
  getBenificiaryInfo(
    chainId: number,
    abortSignal: AbortSignal | undefined
  ): Promise<AxiosResponse<BenificiaryInfoResponse>> {
    return httpClient.get(`/swap/fee/info/${chainId}`, { signal: abortSignal });
  },
  validateSwap(
    data: DataValidateRequest,
    abortSignal: AbortSignal | undefined
  ): Promise<AxiosResponse<DataValidateResponse>> {
    return httpClient.post('/swap/v2/validate', data, { signal: abortSignal });
  },
  getTokensList(chainIds: number[]): Promise<TokenItem[]> {
    return httpClient
      .get('/token-list', {
        params: chainIds,
        paramsSerializer: (params: number[]) =>
          params.map((param: number, index: number) => `chainIds[${index}]=${param}`).join('&'),
      })
      .then(({ data }) => data);
  },
};

export default yoshiApi;
