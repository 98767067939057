// eslint-disable-next-line no-shadow
export enum BridgeTransactionState {
  PROCESSING,
  SUCCESS,
  ERROR,
}

// eslint-disable-next-line no-shadow
export enum CreditProcessorState {
  Created,
  EventNotDeployed,
  EventDeployInProgress,
  EventConfirmed,
  EventRejected,
  CheckingAmount,
  CalculateSwap,
  SwapInProgress,
  SwapFailed,
  SwapUnknown,
  UnwrapInProgress,
  UnwrapFailed,
  ProcessRequiresGas,
  Processed,
  Cancelled,
}
