import { action, computed, makeObservable, observable } from 'mobx';
import React, { createContext, useContext } from 'react';
import { BigNumber, ethers } from 'ethers';
import { DepositData, SwapData, TokenInterface } from '../models';
import { MultiVault } from '../api/assets';

export class SwapStore {
  @observable inputToken: TokenInterface | undefined = undefined;
  @observable inputTokenAmount: string = '';
  @observable inputTokenApprovedAmountBN: BigNumber | undefined = undefined;

  @observable targetEVMToken: TokenInterface | undefined = undefined;

  @observable vaultToken: MultiVault | undefined = undefined;

  @observable tonAmountOut: string | undefined = undefined;

  @observable venomPrice: string = '0';

  @observable error: string | undefined = undefined;

  @observable direction: string | undefined = undefined;

  @observable depositData: DepositData | undefined = undefined;

  @observable swapData: SwapData | undefined = undefined;

  @observable bridgeFee: string | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  @action setInputToken = (value: SwapStore['inputToken']): void => {
    this.inputToken = value;

    this.setInputTokenAmount('');
  };

  @action setInputTokenAmount = (value: SwapStore['inputTokenAmount']): void => {
    this.inputTokenAmount = value;

    this.resetSwapState();
  };

  @action setTargetEvmToken = (value: SwapStore['targetEVMToken']): void => {
    this.targetEVMToken = value;
  };

  @action setTonAmountOut = (value: SwapStore['tonAmountOut']): void => {
    this.tonAmountOut = value;
  };

  @action setInputTokenApprovedAmountBN = (value: SwapStore['inputTokenApprovedAmountBN']): void => {
    this.inputTokenApprovedAmountBN = value;
  };

  @action setVaultToken = (value: SwapStore['vaultToken']): void => {
    this.vaultToken = value;
  };

  @action setError = (value: SwapStore['error']): void => {
    this.error = value;
  };

  @action setDirection = (value: SwapStore['direction']): void => {
    this.direction = value;
  };

  @action setDepositData = (value: SwapStore['depositData']): void => {
    this.depositData = value;
  };

  @action setSwapData = (value: SwapStore['swapData']): void => {
    this.swapData = value;
  };

  @action setBridgeFee = (value: SwapStore['bridgeFee']): void => {
    this.bridgeFee = value;
  };

  @computed get inputTokenAmountBN(): BigNumber | undefined {
    if (Number(this.inputTokenAmount) > 0) {
      return ethers.utils.parseUnits(this.inputTokenAmount, this.inputToken!.decimals);
    }

    return undefined;
  }

  @action setVenomPrice = (value: SwapStore['venomPrice']): void => {
    this.venomPrice = value;
  };

  private resetSwapState = () => {
    this.setTonAmountOut(undefined);
    this.setError(undefined);
    this.setInputTokenApprovedAmountBN(undefined);
    this.setBridgeFee(undefined);
  };
}

const swapStore = new SwapStore();
// @ts-ignore
const SwapStoreContext = createContext<SwapStore>(null);

export const useSwapStore = () => useContext(SwapStoreContext);

const SwapStoreProvider: React.FC<{ store?: SwapStore }> = ({ store = swapStore, children }) => (
  <SwapStoreContext.Provider value={store}>{children}</SwapStoreContext.Provider>
);

export default SwapStoreProvider;
