import { action, makeObservable, observable } from 'mobx';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { ProviderRpcClient } from 'everscale-inpage-provider';
import { useVenomProvider } from '../hooks/venom/useVenomProvider';
import LocalStorageHelper from '../helpers/localStorage';

class VenomWalletStore {
  @observable account: string | undefined = undefined;
  @observable connecting = false;

  constructor() {
    makeObservable(this);
  }

  @action setAccount = (value: VenomWalletStore['account']): void => {
    this.account = value;

    if (value) {
      LocalStorageHelper.setEverWalletAddress(value);
    } else {
      LocalStorageHelper.removeEverWalletAddress();
    }
  };

  @action setConnecting = (value: VenomWalletStore['connecting']): void => {
    this.connecting = value;
  };
}

const venomWalletStore = new VenomWalletStore();
// @ts-ignore
const VenomWalletContext = createContext<VenomWalletStore>(null);

export const useVenomWallet = () => {
  const store = useContext(VenomWalletContext);
  const venomProvider = useVenomProvider();

  useEffect(() => {
    const account = LocalStorageHelper.getEverWalletAddress();

    if (account) {
      store.setAccount(account);
    }
  }, []);

  const login = useCallback(() => {
    store.setConnecting(true);

    return venomProvider
      .connectExtensionProvider()
      .then((connectedAccount) => store.setAccount(connectedAccount))
      .finally(() => store.setConnecting(false));
  }, [venomProvider]);

  const logout = useCallback(() => {
    if (venomProvider.provider?.disconnect) {
      venomProvider.provider.disconnect();
    } else if (hasVenomProvider) {
      const provider = new ProviderRpcClient();
      provider.disconnect();
    }
    store.setAccount(undefined);
  }, [venomProvider.provider]);

  const hasVenomProvider = useMemo<boolean>(() => !!window?.__hasEverscaleProvider, []);

  return Object.assign(store, { login, logout, hasVenomProvider });
};

const VenomWalletProvider: React.FC<{ store?: VenomWalletStore }> = ({ children, store = venomWalletStore }) => (
  <VenomWalletContext.Provider value={store}>{children}</VenomWalletContext.Provider>
);

export default VenomWalletProvider;
