import React, { useEffect } from 'react';
import '../styles/globals.css';
import '../styles/fonts.css';
import '../global';

import type { AppProps } from 'next/app';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { fantom, bsc, mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { infuraProvider } from 'wagmi/providers/infura';
import init from 'eth-ton-abi-converter';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { createPublicClient, http } from 'viem';
import ErrorBoundary from '../helpers/errorBoundary';
import VenomHttpProviderProvider from '../hooks/venom/useVenomHttpProvider';
import EvmTokensStoreProvider from '../store/evmTokensStore';
import VenomProviderProvider from '../hooks/venom/useVenomProvider';
import VenomWalletProvider from '../store/venomWalletStore';
import { ENV } from '../constants';
import SwapStoreProvider from '../store/swapStore';
import ChainIdProvider from '../hooks/evm/useChainId';
import TransactionsHistoryStoreProvider from '../store/transactionsHistoryStore';

import textsEn from '../translations/en.json';
import textsKo from '../translations/ko.json';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, bsc /* fantom */],
  [infuraProvider({ apiKey: ENV.INFURA_KEY }), publicProvider()]
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),

    new WalletConnectConnector({
      chains,
      options: {
        projectId: ENV.WALLET_CONNECT_PROJECT_ID,
        qrModalOptions: {
          themeVariables: {
            '--wcm-z-index': '999999',
          },
        },
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Get EVER',
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

// Set up client

i18next.use(initReactI18next).init({
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: textsEn,
    },
    ko: {
      translation: textsKo,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    init();
  }, []);
  return (
    <WagmiConfig config={config}>
      <ChainIdProvider>
        <ErrorBoundary>
          <VenomHttpProviderProvider>
            <VenomProviderProvider>
              <VenomWalletProvider>
                <TransactionsHistoryStoreProvider>
                  <EvmTokensStoreProvider>
                    <SwapStoreProvider>
                      <Component {...pageProps} />
                    </SwapStoreProvider>
                  </EvmTokensStoreProvider>
                </TransactionsHistoryStoreProvider>
              </VenomWalletProvider>
            </VenomProviderProvider>
          </VenomHttpProviderProvider>
        </ErrorBoundary>
      </ChainIdProvider>
    </WagmiConfig>
  );
}

export default MyApp;
