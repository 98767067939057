import { action, computed, makeObservable, observable } from 'mobx';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import TokenService from '../services/TokenService';
import LocalStorageHelper from '../helpers/localStorage';
import { TokenInterface } from '../models';
import { useChainId } from '../hooks/evm/useChainId';
import { NETWORKS } from '../constants';

export class EvmTokensStore {
  @observable tokens: TokenInterface[] = [];
  @observable bridgetTokens: TokenInterface[] = [];

  constructor() {
    makeObservable(this);
  }

  @action setTokens = (value: any) => {
    this.tokens = value;
  };

  @action setBridgetTokens = (value: any) => {
    this.bridgetTokens = value;
  };

  @action removeToken = (address: string) => {
    this.tokens = this.tokens.filter((token) => token.contractAddress.toLowerCase() !== address.toLowerCase());
  };

  @action updateToken = (index: number, token: TokenInterface) => {
    this.tokens[index] = token;
  };

  @computed get coreToken() {
    return this.tokens.find(({ isCore }) => isCore);
  }
}

const evmTokensStore = new EvmTokensStore();

// @ts-ignore
const EvmTokensStoreContext = createContext<EvmTokensStore>(null);

export const useEvmTokensStore = () => {
  const { chainId } = useChainId();
  const tokensStore = useContext(EvmTokensStoreContext);

  useEffect(() => {
    const isCorrectNetwork = NETWORKS.CHAINS_LIST.map((chain) => chain.id).includes(chainId);
    if (isCorrectNetwork) {
      TokenService.getTokensByNetwork(chainId).then((tokensByNetwork) => {
        const customTokens = LocalStorageHelper.getCustomTokens().filter(
          (customToken: TokenInterface) => customToken.customTokenChainId === chainId
        );
        tokensStore.setTokens(tokensByNetwork.concat(customTokens));
      });

      TokenService.getBridgedTokensByNetwork(chainId).then((tokensByNetwork) => {
        tokensStore.setBridgetTokens(tokensByNetwork);
      });
    }
  }, [chainId]);

  const addCustomToken = useCallback((token: TokenInterface) => {
    tokensStore.setTokens(tokensStore.tokens.concat(token));
    const customTokens = LocalStorageHelper.getCustomTokens();
    customTokens.push(token);
    LocalStorageHelper.setCustomTokens(customTokens);
  }, []);

  const deleteCustomToken = useCallback((address: string) => {
    tokensStore.removeToken(address);
    const customTokens = LocalStorageHelper.getCustomTokens().filter(
      (token: TokenInterface) => token.contractAddress.toLowerCase() !== address.toLowerCase()
    );
    LocalStorageHelper.setCustomTokens(customTokens);
  }, []);

  return Object.assign(tokensStore, { addCustomToken, deleteCustomToken });
};

const EvmTokensStoreProvider: React.FC<{ store?: EvmTokensStore }> = ({ children, store = evmTokensStore }) => (
  <EvmTokensStoreContext.Provider value={store}>{children}</EvmTokensStoreContext.Provider>
);

export default EvmTokensStoreProvider;
