import { ChainId } from '../enums';
import { TokenInterface } from '../models';

export const USDC_TOKENS = {
  [ChainId.Fantom]: {
    contractAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: './USDCIcon.png',
    isCore: false,
    isBridged: true,
    vault: '0xb05a3640132642e6297980376129354ee21a9fc6',
    venomEthConfig: '0:163f95e2779392f4817a7486464166ac2eeb9c6c8ad2a9e751cdd8b7a3be8c12',
    venomAddress: '0:c37b3fafca5bf7d3704b081fde7df54f298736ee059bf6d32fac25f5e6085bf6',
    venomDecimals: 6,
  },
  [ChainId.BSC]: {
    contractAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 18,
    logoURI: './USDCIcon.png',
    isCore: false,
    isBridged: true,
    vault: '0x65950dd2a3d8316c197bda1a353aed046035b1c9',
    venomEthConfig: '0:66728176bf4b920d7921683e22452d8b95f665de4237a83003f03ae54b3f0823',
    venomAddress: '0:c37b3fafca5bf7d3704b081fde7df54f298736ee059bf6d32fac25f5e6085bf6',
    venomDecimals: 6,
  },
  [ChainId.Ethereum]: {
    contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: './USDCIcon.png',
    isCore: false,
    isBridged: true,
    vault: '0xf8a0d53ddc6c92c3c59824f380c0f3d2a3cf521c',
    venomEthConfig: '0:330968ee73b469ee7027bcff8ac76d85419418bfe1be88b70cddce1a95802830',
    venomAddress: '0:c37b3fafca5bf7d3704b081fde7df54f298736ee059bf6d32fac25f5e6085bf6',
    venomDecimals: 6,
  },
} as { [networkID: number]: TokenInterface };
