import React, { createContext, useContext } from 'react';
import { ProviderRpcClient } from 'everscale-inpage-provider';
import { AppErrors } from '../../enums';

export class VenomProviderService {
  provider: ProviderRpcClient | undefined;

  async connectExtensionProvider(): Promise<string> {
    if (!this.provider) {
      this.provider = this.createExtensionProvider();
    }

    await this.provider.ensureInitialized();

    let accountInteraction;
    try {
      const permissions = await this.provider.requestPermissions({
        permissions: ['basic', 'accountInteraction'],
      });

      accountInteraction = permissions.accountInteraction;
    } catch (e) {
      console.error(e);
    }

    if (!accountInteraction) {
      throw new Error('Insufficient permission');
    }

    return accountInteraction.address.toString();
  }

  private createExtensionProvider(): ProviderRpcClient {
    // eslint-disable-next-line no-prototype-builtins
    if (!window.hasOwnProperty('__ever')) {
      throw new Error(AppErrors.VENOM_EXTENSION_NOT_INSTALLED);
    }

    return new ProviderRpcClient({
      forceUseFallback: true,
      // @ts-ignore-start
      fallback: () => Promise.resolve(window.__ever),
    });
  }
}

const venomProviderService = new VenomProviderService();
// @ts-ignore
const VenomProviderContext = createContext<VenomProviderService>(null);

export const useVenomProvider = () => useContext(VenomProviderContext);

const VenomProviderProvider: React.FC<{ service?: VenomProviderService }> = ({
  children,
  service = venomProviderService,
}) => <VenomProviderContext.Provider value={service}>{children}</VenomProviderContext.Provider>;

export default VenomProviderProvider;
