import React, { createContext, useContext } from 'react';
import { EverscaleStandaloneClient as BrowserStandalone } from 'everscale-standalone-client';
import { EverscaleStandaloneClient as NodeStandalone } from 'everscale-standalone-client/nodejs';
import { ProviderRpcClient } from 'everscale-inpage-provider';

const httpProvider = new ProviderRpcClient({
  forceUseFallback: true,
  fallback: () =>
    BrowserStandalone.create({
      connection: 'mainnetJrpc',
    }).catch(() =>
      NodeStandalone.create({
        connection: 'mainnetJrpc',
      })
    ),
});

// @ts-ignore
const VenomHttpProviderContext = createContext<ProviderRpcClient>(null);

export const useVenomHttpProvider = () => useContext(VenomHttpProviderContext);

const VenomHttpProviderProvider: React.FC<{ provider?: ProviderRpcClient }> = ({
  provider = httpProvider,
  children,
}) => <VenomHttpProviderContext.Provider value={provider}>{children}</VenomHttpProviderContext.Provider>;

export default VenomHttpProviderProvider;
