/* eslint-disable no-shadow */
export enum CalculationErrors {
  EMPTY_TARGET = 'Cannot find token for bridging',
  NO_ROUTES = 'No routes',
  USDT_NOT_FOUND = 'USDT Token not found',
  NO_TOKEN_SELECTED = 'Select token',
  EMPTY_INPUT_TOKEN_AMOUNT = 'Input amount to swap',
  DEPOSIT_FEE_ERROR = 'Problem with deposit fee',
  CANNOT_CALCULATED_EXPECTED_AMOUNT = 'Cannot calc expected amount',
}

export enum AppErrors {
  NO_EVM_ACCOUNT = 'Log in with EVM wallet',
  VENOM_EXTENSION_NOT_INSTALLED = 'No Venom extension installed',
}

export enum SwapErrors {
  CANNOT_BUILD_SWAP_TRANSACTION = 'Cannot build swap transaction',
  NO_ROUTES = 'No routes',
  USDT_NOT_FOUND = 'USDT Token not found',
  NO_TOKEN_SELECTED = 'Select token',
  EMPTY_INPUT_TOKEN_AMOUNT = 'Input amount to swap',
  VALIDATION_ERROR = 'validate error',
  INSUFFICIENT_LIQUIDITY = 'Insufficient liquidity',
}

export enum ZeroXErrors {
  TOO_MANY_REQUESTS = 'Request failed with status code 429',
  INSUFFICIENT_ASSET_LIQUIDITY = 'INSUFFICIENT_ASSET_LIQUIDITY',
}

export enum VenomErrors {
  NO_DEX_ROOT = 'Venom DEX Root not found',
  NO_CREDIT_FACTORY = 'Venom CreditFactory not found',
  ENTER_VENOM_ACCOUNT = 'Enter venom account',
}

export enum RPC_ERROR_CODE {
  USER_REJECTED = 'ACTION_REJECTED',
}
