import { ChainId } from '../enums';
import { ENV } from './env';

export const CONTRACT_ADDRESSES = {
  EVERGETV3: {
    [ChainId.Fantom]: ENV.FTM_EVERGETV3,
    [ChainId.BSC]: ENV.BSC_EVERGETV3,
    [ChainId.Ethereum]: ENV.ETH_EVERGETV3,
  } as { [networkID: number]: `0x${string}` },

  USDC: {
    [ChainId.Fantom]: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    [ChainId.BSC]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    [ChainId.Ethereum]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  } as { [chainId: number]: `0x${string}` },

  USDT: {
    [ChainId.Fantom]: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    [ChainId.BSC]: '0x55d398326f99059fF775485246999027B3197955',
    [ChainId.Ethereum]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  } as { [chainId: number]: `0x${string}` },

  USDT_DECIMALS: {
    [ChainId.Fantom]: 6,
    [ChainId.BSC]: 18,
    [ChainId.Ethereum]: 6,
  } as { [chainId: number]: number },

  WRAPPED_TOKENS: {
    [ChainId.Fantom]: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    [ChainId.BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    [ChainId.Ethereum]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  } as { [chainId: number]: `0x${string}` },

  DEXTAXV3: {
    [ChainId.Fantom]: ENV.FTM_DEXTAXV3,
    [ChainId.BSC]: ENV.BSC_DEXTAXV3,
    [ChainId.Ethereum]: ENV.ETH_DEXTAXV3,
  } as { [chainId: number]: `0x${string}` },

  EVERSCALE_DEX_ADDRESS: '0:5eb5713ea9b4a0f3a13bc91b282cde809636eb1e68d2fcb6427b9ad78a5a9008',
  EVERSCALE_CREDITOR_ADDRESS: '0:5ae128e08b2c17428629e092c1a7bd5c77a83a27fa3b833a31c2eb3d704d7f68',
  EVER_WEVER_ADDRESS: '0:a49cd4e158a9a15555e624759e2e4e766d22600b7800d891e46f9291f044a93d',
};
