export const ENV = {
  ENVIRONMENT: process.env.ENVIRONMENT || 'production',

  APP_URL: process.env.APP_URL || '',

  FTM_ETH_JSONRPC_URL: process.env.FTM_ETH_JSONRPC_URL || '',
  BSC_ETH_JSONRPC_URL: process.env.BSC_ETH_JSONRPC_URL || '',

  INFURA_KEY: process.env.INFURA_KEY || '',
  GETEVER_API_URL: process.env.GETEVER_API_URL || '',
  FTM_DEXTAXV3: process.env.FTM_DEXTAXV3 || '',
  BSC_DEXTAXV3: process.env.BSC_DEXTAXV3 || '',
  ETH_DEXTAXV3: process.env.ETH_DEXTAXV3 || '',
  FTM_EVERGETV3: process.env.FTM_EVERGETV3 || '',
  BSC_EVERGETV3: process.env.BSC_EVERGETV3 || '',
  ETH_EVERGETV3: process.env.ETH_EVERGETV3 || '',
  ZERO_X_API_KEY: process.env.ZERO_X_API_KEY || '',
  YOSHI_APP_URL: process.env.YOSHI_APP_URL || '',
  WALLET_CONNECT_PROJECT_ID: process.env.WALLET_CONNECT_PROJECT_ID || '',
};
