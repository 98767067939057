import { Chain, mainnet } from 'wagmi';
import { createPublicClient, http } from 'viem';
import { mainnet as mainnetViemChain, fantom as fantomViemChain, bsc as bscViemChain } from 'viem/chains';
import { ChainId } from '../enums';
import { ENV } from './env';

const fantom: Chain = {
  id: 250,
  name: 'Fantom',
  network: 'Fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    public: { http: [process.env.FTM_ETH_JSONRPC_URL || 'https://rpc.ankr.com/fantom'] },
    default: { http: [process.env.FTM_ETH_JSONRPC_URL || 'https://rpc.ankr.com/fantom'] },
  },
  blockExplorers: {
    default: { name: 'FtmScan', url: 'https://ftmscan.com' },
  },
};

const binance: Chain = {
  id: 56,
  name: 'BNB Chain',
  network: 'Binance',
  nativeCurrency: { name: 'Binance', symbol: 'BNB', decimals: 18 },
  rpcUrls: {
    public: { http: [process.env.BSC_ETH_JSONRPC_URL || 'https://rpc.ankr.com/bsc'] },
    default: { http: [process.env.BSC_ETH_JSONRPC_URL || 'https://rpc.ankr.com/bsc'] },
  },

  blockExplorers: {
    default: { name: 'BscScan', url: ' https://bscscan.com' },
  },
};

export const NETWORKS = {
  CHAINS_LIST: [
    {
      ...mainnet,
      rpcUrls: {
        public: { http: [`https://mainnet.infura.io/v3/${ENV.INFURA_KEY}`] },
        default: { http: [`https://mainnet.infura.io/v3/${ENV.INFURA_KEY}`] },
      },
    },
    // fantom,
    binance,
  ],

  CHAINS_ICONS: {
    [ChainId.Fantom]: '/FantomIcon.svg',
    [ChainId.Ethereum]: '/EthereumIcon.svg',
    [ChainId.BSC]: '/BinanceSmartIcon.svg',
  } as Record<number, string>,

  PUBLIC_CLIENTS: {
    [ChainId.Ethereum]: createPublicClient({
      chain: {
        ...mainnetViemChain,
        rpcUrls: {
          ...mainnetViemChain.rpcUrls,
          default: {
            http: [`https://mainnet.infura.io/v3/${ENV.INFURA_KEY}`],
          },
        },
      },
      transport: http(),
    }),
    [ChainId.Fantom]: createPublicClient({
      chain: {
        ...fantomViemChain,
        rpcUrls: {
          ...fantomViemChain.rpcUrls,
          default: {
            http: [ENV.FTM_ETH_JSONRPC_URL],
          },
        },
      },
      transport: http(),
    }),
    [ChainId.BSC]: createPublicClient({
      chain: {
        ...bscViemChain,
        rpcUrls: {
          ...bscViemChain.rpcUrls,
          default: {
            http: [ENV.BSC_ETH_JSONRPC_URL],
          },
        },
      },
      transport: http(),
    }),
  },
};
