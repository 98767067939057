import axios from './axios';

export default {
  setToken(token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  get(url: string, data?: any) {
    return axios.get(url, data);
  },

  post(url: string, data: any, config?: any) {
    return axios.post(url, data, config);
  },

  put(url: string, data: any) {
    return axios.put(url, data);
  },

  patch(url: string, data: any) {
    return axios.patch(url, data);
  },

  delete(url: string, data: any) {
    return axios.delete(url, data);
  },
};
