import React, { createContext, useContext } from 'react';
import { action, makeObservable, observable } from 'mobx';
import LocalStorageHelper from '../helpers/localStorage';
import { LocalTransactionData, RequireOne, TransactionData } from '../models';

export class TransactionsHistoryStore {
  @observable transactions: TransactionData[] = [];

  constructor() {
    makeObservable(this);

    this.loadTransactions();
  }

  @action private setTransactions = (value: TransactionsHistoryStore['transactions']): void => {
    this.transactions = value;
    LocalStorageHelper.setTransactionHistory(JSON.stringify(value));
  };

  addTransaction = (transaction: LocalTransactionData) => {
    const newTransactions = [...this.transactions, transaction];
    this.setTransactions(newTransactions);
  };

  updateTransaction(transaction: RequireOne<TransactionData, 'evmTxId' | 'evmChainId'>): void {
    const currentTransactionIdx = this.transactions.findIndex(
      ({ evmTxId, evmChainId }) => transaction.evmTxId === evmTxId && transaction.evmChainId === evmChainId
    );

    const newTransactions = this.transactions.slice();
    newTransactions.splice(currentTransactionIdx, 1, { ...newTransactions[currentTransactionIdx], ...transaction });

    this.setTransactions(newTransactions);
  }

  private loadTransactions(): void {
    try {
      const storedTransactions = LocalStorageHelper.getTransactionsHistory();

      const parsedTransactions = storedTransactions.map((transaction: any) => ({
        ...transaction,
      })) as TransactionData[];

      this.setTransactions(parsedTransactions);
    } catch (err) {
      this.setTransactions([]);
    }
  }
}

const transactionsHistoryStore = new TransactionsHistoryStore();
// @ts-ignore
const TransactionsHistoryStoreContext = createContext<TransactionsHistoryStore>(null);

const TransactionsHistoryStoreProvider: React.FC<{ store?: TransactionsHistoryStore }> = ({
  store = transactionsHistoryStore,
  children,
}) => <TransactionsHistoryStoreContext.Provider value={store}>{children}</TransactionsHistoryStoreContext.Provider>;

export const useTransactionsHistoryStore = () => useContext(TransactionsHistoryStoreContext);

export default TransactionsHistoryStoreProvider;
