import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useNetwork, useSwitchNetwork, Chain } from 'wagmi';
import { NETWORKS } from '../../constants';
import { ChainId } from '../../enums';

const ChainIdContext = createContext<{
  chainId: number | undefined;
  switchNetwork: (networkId: number) => void;
  chain:
    | (Chain & {
        unsupported?: boolean;
      })
    | undefined;
  // @ts-ignore
}>(null);

const ChainIdProvider: React.FC = ({ children }) => {
  const { chain } = useNetwork();

  const { switchNetworkAsync: switchWalletNetwork } = useSwitchNetwork({});
  const [chainId, setChainId] = useState<number>();

  useEffect(() => {
    if (chain?.id) {
      setChainId(chain.id);
    }
  }, [chain?.id]);

  const addNetworkToMetamask = async (networkId: number) => {
    let provider = window?.ethereum;
    const newNetwork = NETWORKS.CHAINS_LIST.find((network) => network.id === networkId);

    if (provider?.providers) {
      provider = provider.providers.find((providerItem: any) => providerItem.isMetaMask);
    }
    if (!provider || !newNetwork) {
      return;
    }

    await provider.request({
      // @ts-ignore
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: newNetwork.network,
          // @ts-ignore
          rpcUrls: newNetwork.rpcUrls.default.http,
          chainName: newNetwork.name,
          nativeCurrency: newNetwork.nativeCurrency,
        },
      ],
    });
  };

  const switchNetwork = useCallback(
    (newNetworkId: number) => {
      if (switchWalletNetwork) {
        switchWalletNetwork(newNetworkId).catch((err) => {
          if (err?.message === 'Error adding chain') {
            addNetworkToMetamask(newNetworkId).then(() => switchWalletNetwork(newNetworkId));
          }
        });
      } else {
        setChainId(newNetworkId);
      }
    },
    [switchWalletNetwork]
  );
  const val = { chainId, switchNetwork, chain };

  return <ChainIdContext.Provider value={val}>{children}</ChainIdContext.Provider>;
};
export const useChainId = () => useContext(ChainIdContext);
export default ChainIdProvider;
