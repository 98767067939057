// eslint-disable-next-line no-shadow
export enum ChainId {
  Fantom = 250,
  Ethereum = 1,
  BSC = 56,
}

// eslint-disable-next-line no-shadow
export enum EvmWallet {
  METAMASK = 'metaMask',
  WALLETCONNECT = 'walletConnect',
  COINBASE = 'coinbaseWallet',
}

// eslint-disable-next-line no-shadow
export enum ParaswapPriceSide {
  SELL = 'SELL',
}
