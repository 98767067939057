import { LOCAL_STORAGE_KEYS } from '../constants';
import { TokenInterface, TransactionData } from '../models';

type RateType = {
  contractAddress: string;
  fromContractsCount: number;
  toContractsCount: number;
};

let localStorageProxy: any;

try {
  localStorageProxy = window.localStorage;
} catch (e) {
  localStorageProxy = {
    getItem: (name: any) => {},
    setItem: (name: any, value: any) => {},
    removeItem: (name: any) => {},
  };
}

const LocalStorageHelper = {
  getItem: (name: string) => localStorageProxy.getItem(name),
  setItem: (name: string, value: any) => localStorageProxy.setItem(name, value),
  removeItem: (name: string) => localStorageProxy.removeItem(name),

  getCustomTokens(): TokenInterface[] {
    return JSON.parse(this.getItem(LOCAL_STORAGE_KEYS.CUSTOM_TOKENS) || '[]') as TokenInterface[];
  },

  setCustomTokens(customTokens: TokenInterface[]) {
    this.setItem(LOCAL_STORAGE_KEYS.CUSTOM_TOKENS, JSON.stringify(customTokens));
  },

  getEverWalletAddress(): string | null {
    return this.getItem(LOCAL_STORAGE_KEYS.EVER_ADDRESS);
  },

  removeEverWalletAddress() {
    this.removeItem(LOCAL_STORAGE_KEYS.EVER_ADDRESS);
  },

  setEverWalletAddress(address: string) {
    this.setItem(LOCAL_STORAGE_KEYS.EVER_ADDRESS, address);
  },

  getTransactionsHistory(): TransactionData[] | [] {
    return JSON.parse(this.getItem(LOCAL_STORAGE_KEYS.TRANSACTIONS_HISTORY) || '[]');
  },

  setTransactionHistory(history: string) {
    this.setItem(LOCAL_STORAGE_KEYS.TRANSACTIONS_HISTORY, history);
  },
};

export default LocalStorageHelper;
