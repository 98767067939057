import Web3 from 'web3';
import Cache from '../helpers/cache';
import { TokenInterface } from '../models';
import { ChainId } from '../enums';
import GeteverApi from '../api/getever';
import { ABI, CONTRACT_ADDRESSES, ENV, NETWORKS } from '../constants';
import yoshiApi from '../api/yoshi';

const cache = new Cache();

const TokenService = {
  async __getTokensByNetwork(networkId: number): Promise<TokenInterface[]> {
    const tokens = await yoshiApi.getTokensList([networkId]);
    const bridgedTokens = (await GeteverApi.getBridgedTokensByNetwork(networkId)).map(
      ({
        id,
        attributes: {
          name,
          address,
          // eslint-disable-next-line camelcase
          venom_eth_config,
          venomAddress,
          venomDecimals,
        },
      }: any) => ({
        id,
        name,
        contractAddress: address,
        venomEthConfig: venom_eth_config,
        venomAddress,
        venomDecimals,
      })
    );

    return tokens.map((tokenItem) => {
      const bridgedToken = bridgedTokens.find(
        (token) => token.contractAddress.toLowerCase() === tokenItem.contractAddress.toLowerCase()
      );
      return {
        ...tokenItem,
        isBridged: !!bridgedToken,
        venomEthConfig: bridgedToken?.venomEthConfig ?? '',
        venomAddress: bridgedToken?.venomAddress ?? '',
        venomDecimals: bridgedToken?.venomDecimals ?? '',
        logoURI: /http|https/.test(tokenItem.logoURI || '')
          ? tokenItem.logoURI
          : `${ENV.YOSHI_APP_URL}/${tokenItem.logoURI}`,
      };
    });
  },

  async __getBridgedTokensByNetwork(networkId: number): Promise<TokenInterface[]> {
    const tokens = await this.getTokensByNetwork(networkId);
    return tokens.filter((token) => token.isBridged);
  },

  async getTokensByNetwork(networkId: ChainId = ChainId.Fantom): Promise<TokenInterface[]> {
    return cache.getOrSetAsync(`tokens_${networkId}`, () => this.__getTokensByNetwork(networkId));
  },

  async getBridgedTokensByNetwork(networkId: ChainId = ChainId.Fantom): Promise<TokenInterface[]> {
    return cache.getOrSetAsync(`bridged_tokens_${networkId}`, () => this.__getBridgedTokensByNetwork(networkId));
  },

  async getBeneficiaryFeePercentage(chainId: number) {
    const DexTaxV3Address = CONTRACT_ADDRESSES.DEXTAXV3[chainId];

    const rpcUrl = NETWORKS.CHAINS_LIST.find((chain) => chain.id === chainId)?.rpcUrls.default.http[0];

    if (!rpcUrl) {
      throw new Error('rpc not found');
    }
    const httpProvider = new Web3.providers.HttpProvider(rpcUrl);
    const web3 = new Web3(httpProvider);

    const dexTaxV3Contract = new web3.eth.Contract(ABI.DexTaxV3, DexTaxV3Address);
    const fee = await dexTaxV3Contract.methods.beneficiaryFeePercentage().call();

    return fee;
  },
};

export default TokenService;
