/* eslint-disable camelcase */
import { ENV } from '../constants';
import { createAxios } from './axios';

const httpClient = createAxios(ENV.GETEVER_API_URL!);

type ConfigTypes = {
  use_custom_strategy: boolean;
  swap_bridged_tokens: boolean;
  evm_1_slippage: number;
  evm_56_slippage: number;
  evm_250_slippage: number;
  venom_slippage: number;
};

export default class VenomgetApi {
  static getConfig(): Promise<ConfigTypes> {
    return httpClient.get('/api/config').then(({ data: { data } }) => data.attributes);
  }

  static getDefaultToken(data: { priceInUSDT: number; chainId: number }): Promise<{
    name: string;
    address: `0x${string}`;
    venomEthConfig: string;
    chainId: number;
  }> {
    return httpClient.post('/api/get-default-token', data).then(({ data: token }) => token);
  }

  static getTokensByNetwork(networkId: Number): Promise<any> {
    return httpClient
      .get(`/api/tokens?filters[network][chain_id][$eq]=${networkId}`)
      .then(({ data: { data: token } }) => token);
  }

  static getBridgedTokensByNetwork(chainId: number): Promise<any[]> {
    return httpClient
      .get(`/api/bridged-tokens?populate=*&filters[network][chain_id][$eq]=${chainId}&publicationState=preview`)
      .then(({ data: { data } }) => data);
  }

  static getTokens(): Promise<any[]> {
    return httpClient.get(`/api/tokens`).then(({ data: { data: token } }) => token);
  }
}
