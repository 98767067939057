class CacheHelper {
  __storage: { [index: string]: any };

  __evaluating: { [index: string]: Promise<any> };

  constructor() {
    this.__storage = {};
    this.__evaluating = {};
  }

  has = (key: string): boolean => {
    key = key.toLowerCase();
    return Object.prototype.hasOwnProperty.call(this.__storage, key);
  };

  evaluating = (key: string): boolean => {
    key = key.toLowerCase();
    return Object.prototype.hasOwnProperty.call(this.__evaluating, key);
  };

  getKey = (key: string): any => {
    key = key.toLowerCase();
    if (this.evaluating(key)) {
      return this.__evaluating[key];
    }
    if (this.has(key)) {
      return this.__storage[key];
    }
    throw new Error(`Key ${key} doesn't exist`);
  };

  getOrSet = (key: string, getter: any): any => {
    key = key.toLowerCase();
    if (Object.prototype.hasOwnProperty.call(this.__storage, key)) {
      return this.__storage[key];
    }
    return this.setAndGet(key, getter);
  };

  getOrSetAsync = (key: string, getter: any): Promise<any> => {
    key = key.toLowerCase();
    if (this.evaluating(key)) {
      return this.__evaluating[key];
    }
    if (this.has(key)) {
      return this.__storage[key];
    }
    return this.setAsyncAndGet(key, getter);
  };

  setAndGet = (key: string, getter: any): any => {
    key = key.toLowerCase();
    this.__storage[key] = getter();
    return this.__storage[key];
  };

  setAsyncAndGet = async (key: string, getter: any): Promise<any> => {
    key = key.toLowerCase();
    const promise = getter();
    this.__evaluating[key] = promise;
    this.__storage[key] = await promise;
    delete this.__evaluating[key];
    return this.__storage[key];
  };

  clearKeys = (keyRegex: string): void => {
    Object.keys(this.__storage)
      .filter((key) => new RegExp(keyRegex).test(key))
      .forEach((key) => this.clearKey(key));
  };

  clearKey = (key: string): void => {
    key = key.toLowerCase();
    delete this.__evaluating[key];
    delete this.__storage[key];
  };
}

export default CacheHelper;
